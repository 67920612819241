import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  fetchObjectById,
  ObjectProps,
  postReqGetOffer,
} from '../../api'
import FacilityList from './components'
import Questions from './components/Questions'
import styles from './index.module.scss'
import Button from '../../components/Button'
import { Formik } from 'formik'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import classNames from 'classnames'
import {
  GoogleMap,
  Marker,
  useLoadScript,
} from '@react-google-maps/api'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import 'react-calendar/dist/Calendar.css'
import ModalOffer from '../../components/ModalOffer'
import { useScrollToTop } from '../../components/ScrollToTop'
import Carousel from './components/Carousel'
import { validateRussianPhoneNumber } from '../../helpers'
import success from '../../assets/success.svg'
import dayjs from "dayjs"

const cn = classNames.bind(styles)
const ObjectPage = () => {
  const { id } = useParams()
  const [record, setRecord] = useState<ObjectProps | undefined>(
    undefined,
  )
  const [value, onChange] = useState([new Date(), new Date()])
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
  })
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [step, setStep] = useState<'form' | 'success'>('form')
  const isFormStep = step === 'form'

  useEffect(() => {
    id && fetchObjectById(id, setRecord)
  }, [])

  useScrollToTop()

  const equipments = record?.facilities?.filter(
    (item) => item.category === 'Оборудование',
  )
  const cabins = record?.facilities?.filter(
    (item) => item.category === 'Салон и Каюты',
  )
  const entertainments = record?.facilities?.filter(
    (item) => item.category === 'Развлечения',
  )

  const center = useMemo(
    () => ({ lat: record?.lat || 0, lng: record?.lon || 0 }),
    [record],
  )

  return (
    <main>
      {record && <Carousel record={record} />}

      <section className={cn('container')}>
        <div className={styles.Main}>
          <div className={styles.LeftContainer}>
            <span className={styles.Country}>
              {record?.country?.name}
            </span>

            <h1 className={styles.Title}>{record?.name}</h1>

            <h2 className={styles.Subtitle}>Описание</h2>

            <p className={styles.Description}>
              {record?.description}
            </p>

            {!!record?.facilities?.length && (
              <h2 className={styles.Subtitle}>Удобства</h2>
            )}

            {!!record?.facilities?.length && (
              <div className={styles.FacilitiesContainer}>
                <div>
                  {!!equipments?.length && (
                    <FacilityList
                      title="Оборудование"
                      list={equipments || []}
                    />
                  )}

                  {!!entertainments?.length && (
                    <FacilityList
                      title="Развлечения"
                      list={entertainments || []}
                    />
                  )}
                </div>

                <div>
                  {!!cabins?.length && (
                    <FacilityList
                      title="Салон и Каюты"
                      list={cabins || []}
                    />
                  )}
                </div>
              </div>
            )}

            {!equipments?.length &&
              !equipments?.length &&
              !cabins?.length && (
              <div
                className={cn(
                  styles.FacilitiesContainer,
                  styles.FacilitiesContainerWithoutTitle,
                )}
              >
                <FacilityList
                  title=""
                  list={record?.facilities || []}></FacilityList>
              </div>
            )}

            <h2 className={styles.Subtitle}>Местоположение</h2>

            <div className={styles.MapContainer}>
              {isLoaded && record && (
                <GoogleMap
                  mapContainerClassName="map-container"
                  center={center}
                  zoom={10}
                >
                  <Marker
                    position={{
                      lat: record?.lat || 0,
                      lng: record?.lon || 0,
                    }}
                  />
                </GoogleMap>
              )}
            </div>

            <h2 className={styles.Subtitle}>
              Часто задаваемые вопросы
            </h2>

            <Questions />
          </div>

          <div className={styles.FormContainer}>
            {isFormStep && (
              <>
                <h3 className={styles.RequestTitle}>
                  Оставить заявку
                </h3>

                <p className={styles.RequestDescription}>
                  Наш менеджер свяжется с вами <br />
                  в течении 10 минут и расскажет <br />
                  подробнее
                </p>
              </>
            )}

            {isFormStep ? (
              <Formik
                initialValues={{
                  phone: '',
                  comment: '',
                }}
                validate={(values) => {
                  const errors = {}
                  if (!validateRussianPhoneNumber(values.phone)) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    errors.phone = 'Неверный формат'
                  }
                  return errors
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setSubmitting(false)
                  postReqGetOffer({
                    object: id,
                    phone: values.phone,
                    comment: values.comment,
                    name: '-',
                    middle_name: '-',
                    surname: '-',
                    email: 'test@mail.ru',
                    start_at: dayjs(value?.[0] || new Date()).hour(12).minute(0).second(0).format('YYYY-MM-DDTHH:mm'),
                    end_at:  dayjs(value?.[1]  || new Date()).hour(12).minute(0).second(0).format('YYYY-MM-DDTHH:mm'),
                  })
                  setStep('success')
                  resetForm()
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  /* and other goodies */
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className={styles.Form}
                  >
                    <input
                      type="tel"
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      placeholder="Номер телефона"
                      className={cn(
                        styles.FormInput,
                        touched.phone && errors.phone
                          ? styles.FormErrorInput
                          : '',
                      )}
                    />
                    {touched.phone && errors.phone && (
                      <span className={styles.FormError}>
                        {errors.phone}
                      </span>
                    )}

                    {
                      <DateRangePicker
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        onChange={onChange}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        value={value}
                        className={cn(
                          styles.DateRangePicker,
                          isCalendarOpen
                            ? styles.DateRangePickerActive
                            : '',
                        )}
                        calendarIcon={null}
                        clearIcon={null}
                        minDate={new Date()}
                        onCalendarOpen={() => setIsCalendarOpen(true)}
                        onCalendarClose={() =>
                          setIsCalendarOpen(false)
                        }
                        locale="ru"
                      />
                    }

                    <input
                      className={styles.FormInput}
                      type="text"
                      name="comment"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.comment}
                      placeholder="Комментарий"
                    />
                    {errors.comment && <span>{errors.comment}</span>}

                    <Button
                      type="submit"
                      disabled={
                        isSubmitting ||
                        !values.phone ||
                        !values.comment
                      }
                    >
                      Получить предложение
                    </Button>
                  </form>
                )}
              </Formik>
            ) : (
              <div className={styles.FormSuccess}>
                <div>
                  <img src={success} alt="Success" />
                </div>
                <h2>Готово</h2>

                <p>
                  Наш менеджер свяжется с вами в течении 10 минут и
                  расскажет подробнее
                </p>
              </div>
            )}
          </div>
        </div>
      </section>

      <div className={styles.OfferBtnContainer}>
        <Button onClick={() => setIsModalOpen(true)}>
          Получить предложение
        </Button>
      </div>

      <ModalOffer
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </main>
  )
}
export default ObjectPage
