import React from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'
import { useScrollToTop } from '../../components/ScrollToTop'

const cn = classNames.bind(styles)

const Offer = () => {
  useScrollToTop()
  return (
    <main>
      <section className={cn('container', styles.Container)}>
        <h1>Публичная оферта List Rentals Limited</h1>
        <span>Республика Сейшельские острова</span>
        <p>
          LIST RENTALS LIMITED, компания, зарегистрированная в
          соответствии с законодательством Сейшельских Островов за
          номером 213951 по адресу Suite 15, 1-st floor Oliaji Trade
          Centre, Francis Rachel Street, P.O. Box 1004, Victoria,
          Mahe, Seychelles, действующая через интернет-магазин
          WWW.LISTRENTALS.RU (далее Арендатор), публикует настоящий
          договор, являющийся публичным договором-офертой в адрес как
          физических, так и юридических лиц (далее Арендодатель) о
          нижеследующем:
        </p>

        <h2>1. Предмет настоящей публичной оферты</h2>

        <p>
          1.1. Предметом настоящего договора аренды жилого помещения
          является предоставление Арендодателем за плату во временное
          владение и пользование Арендатора жилого помещения, описание
          которого размещено на интернет-витрине Арендодателя.
        </p>

        <h2>2. Момент заключения договора</h2>

        <p>2.1. Текст данного Договора является публичной офертой.</p>
        <p>
          2.2. Факт совершения конклюдентного действия по принятию
          (1)настоящей оферты (нажатие Арендатором на кнопку
          подтверждения под текстом данной оферты, размещенным на
          сайте Интернет-Магазина) и (2) существенных условий к ней
          (характеристик, адреса, срока аренды и окончательной цены
          аренды объекта) (нажатие Арендатором на кнопку подтверждения
          под текстом данной оферты, размещенным на сайте
          Интернет-Магазина), является безоговорочным принятием
          данного Договора, и Арендатор рассматривается как лицо,
          вступившее с Арендодателем в договорные отношения.
        </p>
        <p>
          2.3. Оформление аренды и последующий расчет за нее
          осуществляются путем оформления Арендатором заказа в
          интернет-магазине на сайте Арендодателя.
        </p>

        <h2>3. Существенные условия договора аренды</h2>

        <p>
          3.1 Существенными условиями по настоящему договору аренды,
          предоставляемыми Арендатору совместно с настоящей офертой
          для окончательного заключения договорных отношений являются:
          - Адрес жилого помещения <br />
          - Характеристика объекта <br />
          - срок аренды <br />
          - согласие Арендатора с тем, что заключая настоящий
          договор-оферту он признает и подтверждает , что помещение,
          передаваемое в аренду, соответствует условиям настоящего
          договора – оферты и принято Арендатором без замечаний.
          Настоящий договор – оферта не требует подписания
          дополнительных документов, свидетельствующих о начале его
          исполнения (в том числе Акта приема-передачи помещения в
          аренду). <br />
          - стоимость аренды <br />
          - окончательная цена аренды объекта с учетом всех скидок и
          возможных дополнительных услуг <br />
        </p>

        <h2>4. Цена аренды</h2>

        <p>
          4.1. Цены на витрине интернет-магазина указаны
          ориентировочные и могут меняться в зависимости от срока
          аренды и запрашиваемых арендатором дополнительных услуг, что
          должно быть отражено в существенных условиях,
          предоставляемых арендатору для заключения договора.
        </p>

        <h2>5. Оплата аренды</h2>

        <p>
          5.1. Оплата аренды осуществляется авансом, в безналичной
          форме на сайте Арендодателя после принятия Арендатором
          настоящего договора-оферты и существенных условий в
          соответствии с настоящим договором. Сумма к оплате должна
          соответствовать принятым Арендатором существенным условиям
          настоящего договора.
        </p>

        <h2>6. Условия аренды</h2>

        <p>
          6.1. На момент заключения настоящего договора помещение,
          находится в полном законном распоряжении Арендодателя, не
          заложено или арестовано, не является предметом исков третьих
          лиц. Указанное гарантируется Арендодателем. Несоблюдение
          изложенного является основанием для расторжения настоящего
          договора аренды жилого помещения.
        </p>

        <p>
          6.2 Передаваемое в аренду жилое помещение находится в
          нормальном состоянии, отвечающем требованиям, предъявляемым
          к эксплуатируемым жилым помещениям, используемым для целей
          проживания в соответствии с назначением аренды.
        </p>

        <p>
          6.3 Арендодатель вправе потребовать расторжения настоящего
          договора и возмещения убытков в случаях, когда им будут
          установлены факты использования помещения не в соответствии
          с условиями договора аренды или назначением арендованного
          жилого помещения.
        </p>

        <h2>7. Обязанности арендодателя</h2>

        <p>
          7.1 Передать жилое помещение, являющееся объектом аренды при
          заключении настоящего Договора.
        </p>

        <p>
          7.2 Передать (предоставить) Арендатору жилое помещение,
          предусмотренное настоящим договором, в состоянии,
          соответствующем условиям договора аренды, назначению
          арендованного помещения и его пригодности для проживания
          людей.
        </p>

        <p>
          7.3 Арендатор согласен с тем, что помещение проверено
          представителем Арендодателя, все системы жизнеобеспечения
          сдаваемого в аренду жилого помещения находятся в исправном
          состоянии и соответствуют условиям договора.
        </p>

        <p>
          7.4 Оказывать в период действия договора аренды Арендатору
          информационную и иную помощь в целях наиболее эффективного
          использования Арендатором жилого помещения, переданного ему
          во временное владение и пользование по настоящему договору
          аренды.
        </p>

        <p>
          7.5 В течение всего срока действия договора аренды жилого
          помещения, осуществлять его капитальный ремонт (по мере
          необходимости) с предоставлением необходимых расходных
          материалов и иных принадлежностей.
        </p>

        <h2>8. Обязанности Арендатора</h2>

        <p>
          8.1 Использовать полученное в аренду жилое помещение в
          соответствии с условиями настоящего договора и исключительно
          по прямому назначению названного помещения, т.е. для
          проживания.
        </p>

        <p>
          8.2. Поддерживать жилое помещение в надлежащем состоянии.
        </p>

        <p>
          8.3 Возместить Арендодателю убытки, причиненные в случае
          повреждения арендованного жилого помещения за которые
          Арендатор несет ответственность в соответствии с действующим
          законодательством или условиями настоящего договора аренды.
        </p>

        <p>
          8.4 Возвратить арендованное жилое помещение в день истечения
          срока аренды.
        </p>

        <h2>
          9. Риск случайной гибели или повреждения жилого помещения
        </h2>

        <p>
          9.1 Если иное не будет установлено сторонами настоящего
          договора аренды в процессе исполнения его условий, риск
          случайной гибели или случайного повреждения переданного в
          аренду жилого помещения в течение срока действия договора
          несет Арендатор.
        </p>

        <p>
          9.2 Страхование принятого в аренду жилого помещения в
          течение всего срока действия договора аренды (от момента
          принятия его от Арендодателя и до момента сдачи помещения
          последнему) осуществляет Арендатор. Выбор видов страхования
          принадлежит Арендатору.
        </p>

        <h2>10. Ответственность сторон и разрешение споров</h2>

        <p>
          10.1. Стороны несут ответственность за неисполнение или
          ненадлежащее исполнение настоящего договора в порядке,
          предусмотренном настоящим договором и действующим
          законодательством Сейшельских островов.
        </p>

        <p>
          10.2. Все споры и разногласия, возникающие при исполнении
          сторонами обязательств по настоящему Договору, решаются
          путем переговоров. В случае невозможности их устранения,
          Стороны имеют право обратиться за судебной защитой своих
          интересов в Арбитражный суд Республики Сейшельские острова.
        </p>

        <h2>
          11. Расторжение договора аренды и возврат денежных средств
        </h2>

        <p>
          11.1. По требованию Арендодателя настоящий договор аренды
          может быть досрочно расторгнут в случаях, когда Арендатор:{' '}
          <br />
          1. пользуется помещением с существенным нарушением условий
          договора или назначения помещения либо с неоднократными
          нарушениями; <br />
          2. существенно ухудшает жилое помещение;
        </p>

        <h2>12. Форс-мажорные обстоятельства</h2>

        <p>
          12.1. Стороны освобождаются от ответственности за
          неисполнение или ненадлежащее исполнение обязательств по
          договору на время действия непреодолимой силы. Под
          непреодолимой силой понимаются чрезвычайные и непреодолимые
          при данных условиях обстоятельства, препятствующие
          исполнению своих обязательств сторонами по настоящему
          Договору. К ним относятся стихийные явления (землетрясения,
          наводнения и т. п.), обстоятельства общественной жизни
          (военные действия, чрезвычайные положения, крупнейшие
          забастовки, эпидемии и т. п.), запретительные меры
          государственных органов (запрещение перевозок, валютные
          ограничения, международные санкции запрета на торговлю и т.
          п.). В течение этого времени стороны не имеют взаимных
          претензий, и каждая из сторон принимает на себя свой риск
          последствия форс-мажорных обстоятельств.
        </p>

        <h2>13. Срок действия договора</h2>

        <p>
          13.1. Настоящий договор вступает в силу с момента принятия
          Арендатором настоящей оферты и существенных условий к ней, и
          заканчивается при полном исполнении обязательств сторонами
        </p>
      </section>
    </main>
  )
}

export default Offer
