import React, { useEffect, useMemo, useState } from 'react'
import InnerPageContainer from '../../components/InnerPageContainer'
import ImgItem from '../../components/ImgItem/ImgItem'
import styles from './index.module.scss'
import { ObjectProps, fetchObjectsByCountryId } from '../../api'
import { Link, useParams } from 'react-router-dom'
import { useScrollToTop } from '../../components/ScrollToTop'
import classNames from 'classnames'

const cn = classNames.bind(styles)
const CountryPage = () => {
  const { id } = useParams()
  const [objects, setObjects] = useState<ObjectProps[]>([])

  useEffect(() => {
    id && fetchObjectsByCountryId(id, setObjects)
  }, [])

  useScrollToTop()

  const country = objects?.[0]?.country

  const realEstate = useMemo(
    () => objects?.filter((item) => item.type === 'real_estate'),
    [objects],
  )
  const yacht = useMemo(
    () => objects?.filter((item) => item.type === 'yacht'),
    [objects],
  )
  const helicopter = useMemo(
    () => objects?.filter((item) => item.type === 'helicopter'),
    [objects],
  )

  return (
    <main>
      <div className={cn('container', styles.CountryImgContainer)}>
        <img src={country?.image} alt={country?.name} />
        <h1>{country?.name}</h1>
      </div>

      <InnerPageContainer
        title="Недвижимость"
        className={styles.InnerPageContainer}
      >
        {realEstate?.map((item) => {
          return (
            <Link key={item.id} to={`/offers/${item.id}`}>
              <ImgItem
                imgUrl={item.images?.[0]?.image}
                name={country?.name}
                description={item.name}
                withImgCaption={false}
              />
            </Link>
          )
        })}
      </InnerPageContainer>

      <InnerPageContainer
        title="Яхты"
        className={styles.InnerPageContainer}
      >
        {yacht?.map((item) => {
          return (
            <Link key={item.id} to={`/offers/${item.id}`}>
              <ImgItem
                imgUrl={item.images?.[0]?.image}
                name={country?.name}
                description={item?.name}
                withImgCaption={false}
              />
            </Link>
          )
        })}
      </InnerPageContainer>

      <InnerPageContainer
        title="Вертолеты"
        className={styles.InnerPageContainer}
      >
        {helicopter?.map((item) => {
          return (
            <Link key={item.id} to={`/offers/${item.id}`}>
              <ImgItem
                imgUrl={item?.images?.[0]?.image}
                name={country?.name}
                description={item?.name}
                withImgCaption={false}
              />
            </Link>
          )
        })}
      </InnerPageContainer>
    </main>
  )
}

export default CountryPage
