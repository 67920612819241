import React from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'
import { useScrollToTop } from '../../components/ScrollToTop'

const cn = classNames.bind(styles)

const Policy = () => {
  useScrollToTop()
  return (
    <main>
      <section className={cn('container', styles.Container)}>
        <h1>Политика конфиденциальности</h1>
        <span>Данная политика действительна с 01 Июня 2023 года</span>

        <p>
          Настоящая политика созадана для того, чтобы проинформировать
          посетителей нашей страницы о том, как мы собираем,
          используем и раскрываем Персональные Данные в случае если
          посетители принимают решение воспользоваться нашими
          Услугами.
        </p>

        <h2>Использование персональной информации</h2>

        <p>
          Если вы решите воспользоваться нашими Услугами, мы можем - в
          соответствии с требованиями конкретной Услуги - попросить
          вас согласиться на сбор и использование вашей персональной
          информации. Данная информация будет использоваться строго
          для оказания Услуги и совершенствования нашего сервиса. Мы
          не будем разглашать вашу персональную информацию третьим
          лицам, кроме как в случаях когда этого требует оказываемая
          Услуга и при условии вашего прямого согласия на такое
          раскрытие.
        </p>

        <h2>Куки</h2>

        <p>
          Несмотря на то, что мы не используем куки - файлы с
          небольшим количеством данных, которые обычно используются
          как уникальные анонимные идентификаторы, направляемые в ваш
          браузер с веб-страниц, которые вы посещаете, и которые
          хранятся во внутренней памяти вашего устройства - возможны
          случаи, когда наши Услуги используют код третьих лиц и
          библиотеки, которые могут использовать куки для сбора
          информации и улучшения их сервисов.
          <br />У вас в любом случае будет возможность принять данные
          куки или отказаться от них. В случае отказа от куки, доступ
          к некоторым разделам сервиса может быть ограничен.
        </p>
      </section>
    </main>
  )
}

export default Policy
