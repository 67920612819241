import React, { useEffect, useState, useRef } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'

const cn = classNames.bind(styles)
export interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  defaultText?: string;
  optionsList: Option[];
  onChange?: (v: string) => void
}

const Select: React.FC<SelectProps> = ({ defaultText, optionsList, onChange }) => {
  const [showOptionList, setShowOptionList] = useState(false)
  const [defaultSelectText, setDefaultSelectText] = useState(defaultText)
  const selectRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    setDefaultSelectText(defaultText)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [defaultText])

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node) &&
      !((event.target as HTMLElement).classList.contains('selected-text') ||
        (event.target as HTMLElement).classList.contains('custom-select-option'))
    ) {
      setShowOptionList(false)
    }
  }

  const handleListDisplay = () => {
    setShowOptionList((prevState) => !prevState)
  }

  const handleOptionClick = (event: React.MouseEvent<HTMLLIElement>) => {
    const name = event.currentTarget.getAttribute('data-name')
    const value = event.currentTarget.getAttribute('data-value')
    if (name) {
      setDefaultSelectText(name)
      setShowOptionList(false)
      onChange && value && onChange(value)
    }
  }

  return (
    <div className={styles.customSelectContainer} ref={selectRef}>
      <div
        className={cn(styles.selectedText, showOptionList ? styles.selectedTextActive : '')}
        onClick={handleListDisplay}
      >
        <span className={defaultSelectText ? styles.Label : styles.LabelHidden}>
          Страна
        </span>

        {defaultSelectText || 'Страна'}
      </div>
      {showOptionList && (
        <ul className={styles.selectOptions}>
          {optionsList.map((option) => (
            <li
              className={styles.customSelectOption}
              data-name={option.label}
              data-value={option.value}
              key={option.value}
              onClick={handleOptionClick}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default Select
