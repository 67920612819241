import React, { useEffect } from 'react'
import Button from '../../components/Button'
import Image404 from '../../assets/404.svg'
import styles from './index.module.scss'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

const cn = classNames.bind(styles)
const Page404 = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const elements = document.querySelectorAll('footer')

    elements.forEach((element) => {
      element.style.display = 'none'
    })

    return () => {
      elements.forEach((element) => {
        element.style.display = ''
      })
    }
  }, [])

  return (
    <main className={cn('container', styles.Main)}>
      <section className={styles.Container}>
        <div className={styles.TextContainer}>
          <span>Ошибка 404</span>
          <h1>
            Страница <br />
            не найдена
          </h1>

          <p>
            Вероятно, страница не существует или вы ошиблись, когда
            вводили адрес в строке браузера.
          </p>

          <Button onClick={() => navigate('/')}>
            Вернуться на главную
          </Button>
        </div>

        <div className={styles.ImageContainer}>
          <img src={Image404} alt="Page not found" />
        </div>
      </section>
    </main>
  )
}

export default Page404
