import React from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import styles from './index.module.scss'
import Button from '../../components/Button'
import SectionBecameAnAgent from '../../components/SectionBecameAnAgent'
import { useScrollToTop } from '../../components/ScrollToTop'

const cn = classNames.bind(styles)
const TeamPage = () => {
  const navigate = useNavigate()
  useScrollToTop()

  return (
    <main className={'container'}>
      <section className={cn('container', styles.Container)}>
        <div className={cn(styles.SectionBanner)}>
          <div className={styles.BannerTop}>
            <h1 className={styles.BannerTitle}>
              Хотите стать членом команды?
            </h1>

            <p className={styles.BannerDescription}>
              Стань частью успешной команды агентов <br />
              LiST Rentals по бронированию туристической <br />
              недвижимости за рубежом!
            </p>
          </div>

          <div className={styles.BannerBottom}>
            <Button onClick={() => navigate('/agent-form')}>
              Подать заявку
            </Button>

            <p className={styles.BannerSubscription}>
              Гарантированный бонус за каждую <br />
              оплаченную заявку по бронированию!
            </p>
          </div>
        </div>
      </section>

      <section className={cn('container', styles.Container)}>
        <div className={styles.DescriptionSection}>
          <h3 className={styles.Title}>
            Схема работы <br />с агентом
          </h3>

          <ul className={styles.AgentList}>
            <li>
              Агент по поручению LiST Rentals осуществляет поиск,
              коммуникацию с клиентами: подбор вилл, яхт, вертолетов
              согласно пожеланий и бюджета клиента. LiST Rentals берет
              на себя все расчёты с зарубежными контрагентами,
              предоставляет базу туристической недвижимости.
            </li>
            <li>
              Клиент, для которого агент забронировал недвижимость,
              должен будет заключить договор бронирования с агентом и
              произвести оплату.
            </li>
            <li>
              Агент перечисляет компании LiST Rentals средства
              согласно агентского договора по каждой заявке
              (поручению) бронирования. Далее LiST Rentals согласно
              отчета агента выплачивает на расчётный счет агента
              вознаграждение по каждой заявке (поручению)
              бронирования.
            </li>
            <li>
              Агент информирует клиентов о любых изменениях по заявке,
              осуществляет все постпродажные процессы и обслуживание в
              коммуникации с LiST Rentals
            </li>
          </ul>
        </div>
      </section>

      <SectionBecameAnAgent />
    </main>
  )
}

export default TeamPage
