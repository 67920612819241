import React from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'
import logo from '../../assets/logo.svg'
import b2pIcon from '../../assets/b2p.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Button from '../Button'
import mailIcon from '../../assets/mail.svg'
import phoneIcon from '../../assets/phone.svg'
import homeIcon from '../../assets/home.svg'

const cn = classNames.bind(styles)

const Footer = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const isReservationPage = location?.pathname?.includes('reservation')

  return !isReservationPage ? (
    <footer className={styles.FooterContainer}>
      <div className={cn('container', styles.FooterContent)}>
        <div className={styles.FooterContentTop}>
          <div className={styles.FooterAgent}>
            <h2 className={styles.FooterTitle}>
              Хотите стать <br />
              агентом?
            </h2>

            <p className={styles.FooterDescription}>
              Хочешь присоединиться к нашей дружной <br />
              команде мегаклассных специалистов?
            </p>
          </div>

          <Button
            className={styles.FooterBtn}
            onClick={() => navigate('/agent-form')}
          >
            Стать агентом
          </Button>
        </div>

        <div className={styles.FooterContentBottom}>
          <div className={styles.LogoContainer}>
            <Link to="/" className={styles.Logo}>
              <img src={logo} alt="Logo" />
            </Link>
            <Link to="/policy" className={styles.PrivacyLink}>
              Политика конфиденциальности
            </Link>
            <Link to="/offer" className={styles.PrivacyLink}>
              Публичная оферта - Прием в аренду
            </Link>
            <Link to="/offer-rental" className={styles.PrivacyLink}>
              Публичная оферта - Сдача в аренду
            </Link>


            <p className={styles.Address}>
              Suite 15, 1st Floor Oliaji Trade Centre, Francis Rachel
              Street, P.O. Box 1004, Victoria, Mahe, Seychelles
            </p>
          </div>

          <div className={styles.FooterAddresses}>
            <div className={styles.FooterB2P}>
              <img src={b2pIcon} alt="Best 2 pay Logo" />
              <Link to="/policy-best-2-pay" className={styles.PrivacyLink}>
                Политика безопасности
              </Link>
            </div>

            <address className={styles.FooterContacts}>
              <a href="tel:+79191313182">
                <img src={phoneIcon} alt="Phone Icon" />
                +7 919 131-31-82
              </a>
              <a href="info@listrentals.ru">
                <img src={mailIcon} alt="Mail Icon" />
                info@listrentals.ru
              </a>
              <Link to="/rental">
                <img src={homeIcon} alt="Home" />
                Разместить свой объект
              </Link>
            </address>
          </div>
        </div>
      </div>
    </footer>
  ) : null
}

export default Footer
