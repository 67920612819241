import React, {useEffect} from 'react'
import Button from '../../components/Button'
import ImageSuccess from '../../assets/success.svg'
import styles from './index.module.scss'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

const cn = classNames.bind(styles)
const BookingSuccessPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const elements = document.querySelectorAll('footer')

    elements.forEach(element => {
      element.style.display = 'none'
    })

    return () => {
      elements.forEach(element => {
        element.style.display = ''
      })
    }
  }, [])

  return (
    <main className={cn('container', styles.Main)}>
      <section className={styles.Container}>
        <div className={styles.ImageContainer}>
          <img src={ImageSuccess} alt="Success"/>
        </div>

        <h1>
          Бронирование <br/>
          подтверждено
        </h1>

        <Button onClick={() => navigate('/')}>
          Вернуться на главную
        </Button>
      </section>
    </main>
  )
}

export default BookingSuccessPage
