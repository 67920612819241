import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { HomePageSectionProps } from './HomePageSection.d'
import Button from '../../../components/Button'
import styles from '../index.module.scss'
import ImgItem from '../../../components/ImgItem/ImgItem'
import {
  ObjectProps,
  fetchCountries,
  fetchObjectList,
} from '../../../api'

const cn = classNames.bind(styles)

const HomePageSection = ({
  title,
  btnUrl,
  titleClassName,
  withImgCaption = false,
  type,
}: HomePageSectionProps) => {
  const navigate = useNavigate()
  const [objectList, setObjectList] = useState<ObjectProps[]>([])

  const isCountry = type === 'countries'
  const getInfo = async () => {
    switch (type) {
      case 'countries':
        return await fetchCountries(setObjectList, 4)
      case 'yachts':
        return await fetchObjectList({ setObjectList, limit: 4, type: 'yacht' })
      case 'helicopters':
        return await fetchObjectList({ setObjectList, limit: 4, type: 'helicopter' })
      case 'recommended-to-visit':
        return await fetchObjectList({ setObjectList, limit: 4, type: 'real_estate' })
      default:
        return await fetchCountries(setObjectList)
    }
  }

  useEffect(() => {
    getInfo()
  }, [])

  const handleSeeAll = () => {
    navigate(btnUrl)
  }

  return (
    <section className={cn(styles.Section, 'container')}>
      <div className={styles.Container}>
        <div className={styles.SectionHeader}>
          <h3 className={titleClassName || ''}>{title}</h3>

          <Button onClick={handleSeeAll}>Смотреть все</Button>
        </div>

        <div className={styles.Products}>
          {objectList?.map((item, index) => {
            if (index > 3) {
              return null
            }

            return (
              <Link
                key={item.id}
                to={`/${isCountry ? 'country' : 'offers'}/${item.id}`}
              >
                <ImgItem
                  imgUrl={
                    isCountry ? item.image : item.images?.[0]?.image
                  }
                  name={isCountry ? item.name : item.country.name}
                  description={item.name}
                  withImgCaption={withImgCaption}
                />
              </Link>
            )
          })}
        </div>

        <Button onClick={handleSeeAll} className={styles.MobileBtn}>
          Смотреть все
        </Button>
      </div>
    </section>
  )
}

export default HomePageSection
