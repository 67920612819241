import React, { useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../assets/logo.svg'
import logoBlue from '../../assets/blue-logo.svg'
import styles from './index.module.scss'
import classNames from 'classnames'

const cn = classNames.bind(styles)

const Header = () => {
  const location = useLocation()
  const ref = useRef(null)
  const [menuIsOpen, setIsOpenMenu] = useState(false)
  const isMainOrRentalPage =
    location.pathname === '/' || location.pathname === '/rental'
  const isBookingPage = location.pathname.includes('/booking')
    && !location.pathname.includes('/success')
    && !location.pathname.includes('/rejected')
  const isReservationPage = location?.pathname?.includes('reservation')
  const handleMenuIconClick = () => {
    if (!menuIsOpen) {
      document.body.classList.add('menu-open')
    } else {
      document.body.classList.remove('menu-open')
    }
    setIsOpenMenu(!menuIsOpen)
  }

  return (
    <header
      className={
        cn(isMainOrRentalPage || isBookingPage || isReservationPage ? styles.HeaderContainerBlue : '',
          isReservationPage ? styles.HeaderReservationPage : '')
      }
    >
      <div className={cn('container', styles.HeaderContent, styles.HeaderContent, isBookingPage ? styles.HeaderBookingContent : '')}>
        <Link to="/" className={styles.Logo}>
          <img src={isMainOrRentalPage || isBookingPage || isReservationPage ? logo : logoBlue} alt="Logo" />
        </Link>

        {
          !isBookingPage && !isReservationPage && (
            <>
              <nav
                className={cn(
                  styles.Nav,
                  !isMainOrRentalPage ? styles.NavBlue : undefined,
                )}
              >
                <Link to="/">Главная</Link>
                <Link to="/team">Наша команда</Link>
                <Link to="/faq">FAQ</Link>
                <Link to="/rental">Разместить свой объект</Link>
              </nav>

              <div className={styles.MobileMenu}>
                <div
                  ref={ref}
                  className={cn(
                    styles.MenuIcon,
                    isMainOrRentalPage ? styles.MenuIconMain : '',
                    menuIsOpen && styles.MenuIconOpen,
                  )}
                  onClick={handleMenuIconClick}
                />

                {menuIsOpen && (
                  <ul
                    className={cn(
                      styles.MenuList,
                      !isMainOrRentalPage ? styles.MenuListWhite : '',
                    )}
                  >
                    <li onClick={() => handleMenuIconClick()}>
                      <Link to="/">Главная</Link>
                    </li>
                    <li onClick={() => handleMenuIconClick()}>
                      <Link to="/team">Наша команда</Link>
                    </li>
                    <li onClick={() => handleMenuIconClick()}>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li onClick={() => handleMenuIconClick()}>
                      <Link to="/rental">Разместить свой объект</Link>
                    </li>
                  </ul>
                )}
              </div>
            </>
          )
        }
      </div>
    </header>
  )
}

export default Header
