import React from 'react'

import styles from './index.module.scss'
import classNames from 'classnames'
import { useScrollToTop } from '../../components/ScrollToTop'
import b2pIcon from '../../assets/b2p-black.svg'
import mirIcon from '../../assets/mir.svg'
import mastercardIcon from '../../assets/mastercard.svg'
import visaIcon from '../../assets/visa.svg'

const cn = classNames.bind(styles)

const PolicyBest2Pay = () => {
  useScrollToTop()

  return (
    <main>
      <section className={cn('container', styles.Container)}>
        <div className={styles.PaySystemsList}>
          <img src={b2pIcon} alt="Логотип Best 2 pay"/>
          <img src={mirIcon} alt="Логотип платежной системы мир"/>
          <img src={mastercardIcon} alt="Логотип платежной системы mastercard"/>
          <img src={visaIcon} alt="Логотип платежной системы visa"/>
        </div>
        <h1>Политика безопасности Бест2Пэй</h1>

        <p>
          Оплатить заказ можно с помощью банковских карт платёжных систем Visa, MasterCard, МИР.
        </p>

        <p>
          При оплате банковской картой безопасность платежей гарантирует процессинговый центр Best2Pay.
        </p>

        <p>
          Приём платежей происходит через защищённое безопасное соединение, используя протокол TLS 1.2.
        </p>

        <p>
          Компания Best2Pay соответствует международным требованиями PCI DSS для обеспечения безопасной обработки реквизитов банковской карты плательщика.
        </p>

        <p>
          Ваши конфиденциальные данные необходимые для оплаты (реквизиты карты, регистрационные данные и др.) не поступают в Интернет-магазин, их обработка производится на стороне процессингового центра Best2Pay и полностью защищена.
        </p>

        <p>
          Никто, в том числе интернет-магазин listrentals.ru, не может получить банковские и персональные данные плательщика.
        </p>

        <p>
          При оплате заказа банковской картой возврат денежных средств производится на ту же самую карту, с которой был произведён платёж
        </p>
      </section>
    </main>
  )
}

export default PolicyBest2Pay
