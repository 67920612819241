import React, {useEffect, useState} from 'react'
import InnerPageContainer from '../../components/InnerPageContainer'
import ImgItem from '../../components/ImgItem/ImgItem'
import styles from './index.module.scss'
import { ObjectProps, fetchCountries } from '../../api'
import { Link } from 'react-router-dom'
import { useScrollToTop } from '../../components/ScrollToTop'

const CountriesPage = () => {
  const [countryList, setCountryList] = useState<ObjectProps[]>([])

  useEffect(() => {
    fetchCountries(setCountryList)
  }, [])

  useScrollToTop()

  return (
    <>
      <InnerPageContainer title='Все страны'>
        {
          countryList?.map((item) => {
            return (
              <Link key={item.id} to={`/country/${item.id}`}>
                <ImgItem
                  withImgCaption={true}
                  imgUrl={item.image}
                  name={item.name}
                  classNameImage={styles.Image}
                  classNameCaption={styles.Caption}
                />
              </Link>
            )
          })
        }
      </InnerPageContainer>
    </>
  )
}

export default CountriesPage
