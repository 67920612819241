import React from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'

const cn = classNames.bind(styles)

export type ButtonProps = {
  /** @default button*/
  tag?: 'button';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({ children, ...props }: ButtonProps) => {
  return (
    <button
      {...props}
      className={cn(styles.Button, props.className || '')}
    >
      {children}
    </button>
  )
}

export default Button