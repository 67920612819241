import React, {useEffect, useState} from 'react'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { Link, useNavigate, useParams } from 'react-router-dom'

import styles from './index.module.scss'
import { useScrollToTop } from '../../components/ScrollToTop'
import {
  fetchBookingById,
  BookingProps,
  ObjectProps,
  fetchObjectById,
  postBookingApproveAndPay,
} from '../../api'
import Button from '../../components/Button'
import { formatNumberWithThousandsSeparator } from '../../helpers'


const cn = classNames.bind(styles)

const BookingApprovePage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [booking, setBooking] = useState<BookingProps | undefined>(undefined)
  const [object, setObject] = useState<ObjectProps | undefined>(undefined)

  useEffect(() => {
    if (id && !booking?.id) {
      fetchBookingById(id, setBooking)
    }
    if (booking?.object) {
      fetchObjectById(booking?.object, setObject)
    }
  }, [booking?.object])

  useScrollToTop()

  const isHelicopter = object?.type === 'helicopter'
  const price = booking?.price ? formatNumberWithThousandsSeparator((+booking.price).toFixed(2)) : 0

  const firstDate = dayjs(booking?.end_at).hour(12).minute(0).second(0)
  const secondDate = dayjs(booking?.start_at).hour(12).minute(0).second(0)
  const dayDiff = firstDate.diff(secondDate, "day")

  const startTime = dayjs(booking?.start_at)
  const endTime = dayjs(booking?.end_at)
  const diffHours = endTime?.diff(startTime, 'hour')

  let forOneNight = 0
  if (booking?.price) {
    forOneNight = +booking.price / (dayDiff || diffHours)
  }
  const forOneNightFormatted = forOneNight
    ? formatNumberWithThousandsSeparator(forOneNight.toFixed(2)) : 0

  const onApproveAndPay = async () => {
    if (id) {
      await postBookingApproveAndPay(id)
        .then((data) => {
          if (data?.data?.payment_url) {
            window.location.href = data?.data?.payment_url
          }
        }).catch(() => {
          navigate('/booking/rejected')
        })
    }
  }

  return (
    <main style={{ overflow: 'hidden' }}>
      <div className={styles.MainBlock}>
        <h1 className={'container'}>
          Подтверждение <br/>
          и оплата бронирования
        </h1>
      </div>

      <section className={cn(styles.Section, 'container')}>
        <div>
          <div className={styles.ObjectInfo}>
            <div className={styles.ObjectImg}>
              <img
                width='72'
                height='72'
                src={object?.images?.[0].image || ''}
                alt={object?.images?.[0].name}
              />
            </div>
            <div className={styles.ObjectData}>
              <span>{object?.name}</span>
              <span>{object?.country?.name}</span>
            </div>
          </div>

          <div>
            <h2>
              Ваши данные
            </h2>

            <div className={styles.UserInfo}>
              <div>
                <span>ФИО</span>
                <span>{`${booking?.customer_surname} ${booking?.customer_name} ${booking?.customer_middle_name}`}</span>
              </div>
              <div>
                <span>Почта</span>
                <span>{booking?.email}</span>
              </div>
              <div>
                <span>Номер телефона</span>
                <span>{booking?.phone}</span>
              </div>
            </div>
          </div>

          {
            booking?.comment && (
              <>
                <h3>
                  Комментарий
                </h3>

                <p>
                  {booking?.comment}
                </p>
              </>
            )
          }

          <h3>
            Дополнительные услуги
          </h3>

          <p>
            Дополнительные услуги можно оплатить на месте картой Visa,
            MasterCard или наличными (Местная валюта или Евро).
          </p>
        </div>

        <div className={styles.PriceBlock}>
          <div className={styles.PriceForNight}>
            {forOneNightFormatted} ₽
            <span>/ {isHelicopter ? 'час' : 'сутки'}</span>
          </div>

          <div className={styles.PriceData}>
            <span>Даты</span>
            <span>
              {
                isHelicopter
                  ? `${firstDate.format('DD.MM.YYYY')} ${startTime.format('HH:MM')}-${endTime.format('HH:MM')}`
                  : `${secondDate.format('DD.MM.YYYY')}-${firstDate.format('DD.MM.YYYY')}`
              }
            </span>
          </div>

          <div className={styles.PriceData}>
            <span>
              {dayDiff || diffHours ? `${forOneNightFormatted} x ${dayDiff || diffHours}` : forOneNightFormatted}
            </span>
            <span>{price} ₽</span>
          </div>

          <Button
            className={styles.PriceBtn}
            onClick={onApproveAndPay}
          >
            Подтвердить и оплатить
          </Button>

          <span className={styles.PriceNotice}>
            Нажатием кнопки &quot;Подтвердить и оплатить&quot; <br/>
            я подтверждаю, что ознакомлен с офертой, <br/>
            размещенной по ссылке
            <Link to='/oferta'>listrentals.ru/oferta</Link>,<br/>
            и принимаю ее в полном объеме наряду <br/>
            с существенными условия договора, <br/>
            предложенными мне компанией &quot;List Rentals Limited&quot;
          </span>
        </div>
      </section>

    </main>
  )
}

export default BookingApprovePage
