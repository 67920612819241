import React, { useEffect, useMemo, useState } from 'react'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

import styles from './index.module.scss'
import success from '../../assets/success.svg'
import { fetchObjectList, ObjectProps, PostOfferProps, postReqGetOffer } from '../../api'
import Button from '../Button'
import Modal from '../Modal'
import { isValidEmail, validateRussianPhoneNumber } from '../../helpers'

interface ModalProps {
  isOpen: boolean
  onClose: (isOpen: boolean) => void
  applicationData?: PostOfferProps
}

const ModalOffer = ({ isOpen, onClose, applicationData }: ModalProps) => {
  const [step, setStep] = useState<'form' | 'success'>('form')
  const [objects, setObjects] = useState<ObjectProps[]>([])
  const isFormStep = step === 'form'

  useEffect(() => {
    if (applicationData?.countryId && applicationData.objectType) {
      fetchObjectList({
        setObjectList: setObjects,
        type: applicationData.objectType,
        countryId: applicationData?.countryId,
      })
    }
  }, [applicationData?.countryId, applicationData?.objectType])

  const randomObject = useMemo(
    () => objects?.[0]?.id,
    [objects],
  )

  const onCloseForm = () => {
    setStep('form')
    onClose(isOpen)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseForm}
      dialogClass={styles.DialogClass}
      styleClose={styles.ModalCloseBtn}
    >
      {
        isFormStep ? (
          <div className={styles.Container}>
            <h2 className={styles.ModalTitle}>
              Укажите контактные данные
            </h2>
            <p className={styles.ModalDescription}>
              Наш менеджер свяжется с вами <br/>
              в ближайшее время для <br/>
              подтверждения бронирования
            </p>

            <Formik
              initialValues={{
                name: '',
                surname: '',
                middle_name: '',
                phone: '',
                email: '',
                comment: '',
              }}
              validate={values => {
                const errors = {}
                if (!values.name) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  errors.name = 'Обязательное поле'
                }

                if (!values.middle_name) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  errors.middle_name = 'Обязательное поле'
                }

                if (!values.surname) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  errors.surname = 'Обязательное поле'
                }

                if (!isValidEmail(values.email)) (
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  errors.email = 'Неверный формат'
                )

                if (!validateRussianPhoneNumber(values.phone)) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  errors.phone = 'Неверный формат'
                }

                return errors
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(false)
                postReqGetOffer({
                  name: values.name,
                  surname: values.surname,
                  middle_name: values.middle_name,
                  phone: values.phone,
                  comment: values.comment,
                  email: values.email,
                  object: applicationData?.object || randomObject,
                  start_at: applicationData?.start_at
                    ? dayjs(applicationData?.start_at).hour(12).minute(0).second(0).format('YYYY-MM-DDTHH:mm')
                    : '',
                  end_at: applicationData?.end_at
                    ? dayjs(applicationData?.end_at).hour(12).minute(0).second(0).format('YYYY-MM-DDTHH:mm')
                    : '',
                })
                setStep('success')
                resetForm()
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                errors,
                touched,
                /* and other goodies */
              }) => (
                <form onSubmit={(e) => {
                  e?.preventDefault()
                  handleSubmit()
                }} className={styles.Form}>
                  <input
                    type="text"
                    name="name"
                    required={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    placeholder='Имя*'
                    className={touched.name && errors.name ? styles.FormErrorInput : ''}
                  />
                  {
                    touched.name && errors.name && (
                      <span className={styles.FormError}>
                        {errors.name}
                      </span>
                    )
                  }

                  <input
                    type="text"
                    required={true}
                    name="middle_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.middle_name}
                    placeholder='Фамилия*'
                    className={touched.middle_name && errors.middle_name ? styles.FormErrorInput : ''}
                  />
                  {
                    touched.middle_name && errors.middle_name && (
                      <span className={styles.FormError}>
                        {errors.middle_name}
                      </span>
                    )
                  }

                  <input
                    type="text"
                    name="surname"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.surname}
                    placeholder='Отчество*'
                    className={touched.surname && errors.surname ? styles.FormErrorInput : ''}
                  />
                  {
                    touched.surname && errors.surname && (
                      <span className={styles.FormError}>
                        {errors.surname}
                      </span>
                    )
                  }

                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder='Почта (example@mail.ru)*'
                    className={touched.email && errors.email ? styles.FormErrorInput : ''}
                  />
                  {
                    touched.email && errors.email && (
                      <span className={styles.FormError}>
                        {errors.email}
                      </span>
                    )
                  }

                  <input
                    type="tel"
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    placeholder='Номер телефона (+79999999999)*'
                    className={touched.phone && errors.phone ?  styles.FormErrorInput : ''}
                  />
                  {
                    touched.phone && errors.phone && (
                      <span className={styles.FormError}>
                        {errors.phone}
                      </span>
                    )
                  }

                  <input
                    type="text"
                    name="comment"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.comment}
                    placeholder='Комментарий'
                  />

                  <Button
                    type="submit"
                    disabled={isSubmitting || !values.phone || !values.email || !values.name || !values.middle_name}
                    className={styles.Button}
                  >
                    Забронировать
                  </Button>

                  <div className={styles.Notice}>
                    Нажатием кнопки &quot;Забронировать &quot; я подтверждаю, <br/>
                    что ознакомлен с офертой, размещенной по ссылке <br/>
                    <Link to='/oferta'>listrentals.ru/oferta</Link>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        ) : (
          <div className={styles.FormSuccess}>
            <div>
              <img src={success} alt="Success"/>
            </div>
            <h2>
              Готово
            </h2>

            <p>
              Наш менеджер свяжется с вами
              в ближайшее время для
              подтверждения бронирования
            </p>
          </div>
        )
      }
    </Modal>
  )
}

export default ModalOffer
