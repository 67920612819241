import React from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'
import { useScrollToTop } from '../../components/ScrollToTop'

const cn = classNames.bind(styles)

const Oferta = () => {
  useScrollToTop()
  return (
    <main>
      <section className={cn('container', styles.Container)}>
        <h1>
          Публичная оферта <br/>
          List Rentals Limited
        </h1>

        <p>
          LIST RENTALS LIMITED, компания, зарегистрированная в соответствии с
          законодательством Сейшельских Островов за номером 213951 по адресу Suite 15, 1-st
          floor Oliaji Trade Centre, Francis Rachel Street, P.O. Box 1004, Victoria, Mahe, Seychelles,
          обладающая должным образом зарегистрированным на территории Российской
          Федерации представительством (ИНН 9909543360, КПП 774791001, ОКПО 43140319,
          юридический адрес 101000, Москва, улица Мясницкая д. 46 строение 7, помещение II,
          комната 1), действующая через интернет-магазин WWW.LISTRENTALS.RU (далее
          Арендодатель), публикует настоящий договор, являющийся публичным договором-офертой
          в адрес как физических, так и юридических лиц (далее Арендатор о нижеследующем:
        </p>

        <h2>
          1. Предмет настоящей публичной оферты.
        </h2>

        <p>
          1.1. Предметом настоящего договора аренды жилого помещения является предоставление
          Арендодателем за плату во временное владение и пользование Арендатора жилого помещения,
          описание которого размещено на интернет-витрине Арендодателя.
        </p>

        <h2>
          2. Момент заключения договора.
        </h2>

        <p>
          2.1. Текст данного Договора является публичной офертой (в соответствии со статьей
          435 и частью 2 статьи 437 Гражданского кодекса РФ).
          <div className={styles.NewLine}/>
          2.2. Факт совершения конклюдентного действия по принятию (1)настоящей оферты
          (нажатие на кнопку подтверждения под текстом данной оферты, размещенным на сайте Интернет-Магазина)
          и (2) существенных условий к ней (характеристик, адреса, срока аренды и окончательной цены аренды объекта)
          (нажатие на кнопку подтверждения под текстом данной оферты, размещенным на сайте Интернет-Магазина),
          является безоговорочным принятием данного Договора, и Арендатор рассматривается как
          лицо, вступившее с Арендодателем в договорные отношения.
          <div className={styles.NewLine}/>
          2.3. Оформление заказ аренды и последующий расчет за нее осуществляются путем оформления покупателем
          заказа в интернет-магазине, расчет осуществляется также на сайте Арендодателя.
        </p>

        <h2>
          3. Существенные условия договора аренды.
        </h2>

        <p>
          3.1 Существенными условиями по настоящему договору аренды, предоставляемыми
          Покупателю совместно с настоящей офертой для окончательного заключения договорных отношений являются:

          <ul className={styles.List}>
            <li>- Адрес жилого помещения</li>
            <li>- Характеристика объекта</li>
            <li>- срок аренды</li>
            <li>- срок передачи помещения в аренду</li>
            <li>- стоимость аренды</li>
            <li>- выявленные недостатки жилого помещения</li>
            <li>- окончательная цена аренды объекта с учетом всех скидок и возможных дополнительных услуг</li>
          </ul>

        </p>

        <h2>
          4. Цена аренды.
        </h2>

        <p>
          4.1. Цены на витрине интернет-магазина указаны ориентировочные и могут меняться в
          отличии от срока аренды и запрашиваемых арендатором дополнительных услуг, что
          должно быть отражено в существенных условиях, предоставляемых арендатору для заключения договора.
        </p>

        <h2>
          5. Оплата аренды.
        </h2>

        <p>
          5.1. Оплата аренды осуществляется авансом, в безналичной форме на сайте Арендодателя после принятия
          Арендатором настоящего договора-оферты и существенных условий в соответствии с настоящим договором.
          Сумма к оплате должна соответствовать принятым Арендатором существенным условиям настоящего договора.
        </p>

        <h2>
          6. Условия аренды.
        </h2>

        <p>
          6.1. На момент заключения настоящего договора помещение, находится в полном законном распоряжении
          Арендодателя, не заложено или арестовано, не является предметом исков третьих лиц.
          Указанное гарантируется Арендодателем. Несоблюдение изложенного является основанием
          для расторжения настоящего договора аренды жилого помещения.
          <div className={styles.NewLine}/>
          6.2 Передаваемое в аренду жилое помещение находится в нормальном состоянии, отвечающем
          требованиям, предъявляемым к эксплуатируемым жилым помещениям, используемым для целей
          проживания в соответствии с назначением аренды.
          <div className={styles.NewLine}/>
          6.3 Арендодатель вправе потребовать расторжения настоящего договора и возмещения
          убытков в случаях, когда им будут установлены факты использования помещения не в
          соответствии с условиями договора аренды или назначением арендованного жилого помещения.
          <div className={styles.NewLine}/>
          6.4 Арендодатель несет ответственность за недостатки сданного им в аренду по
          настоящему договору жилого помещения, полностью или частично препятствующие
          пользованию им, несмотря на то, что при сдаче его в аренду (заключении договора)
          Арендодатель мог и не знать о наличии указанных недостатков. При этом Арендатор может
          потребовать от Арендодателя возмещения своих нарушенных имущественных интересов или
          потребовать досрочного расторжения настоящего договора аренды жилого помещения.
          <div className={styles.NewLine}/>
          6.5 В случаях, когда недостатки арендованного жилого помещения были оговорены при
          заключении договора или были известны Арендатору либо должны были быть выявлены
          им при осмотре объекта аренды при заключении договора или передаче его Арендатору
          в пользование по договору, Арендодатель не отвечает за подобные недостатки.
        </p>

        <h2>
          7. Обязанности арендодателя.
        </h2>

        <p>
          7.1 Передать по документу (акту), определенному сторонами настоящего договора и
          подтверждающему факт передачи, жилое помещение, являющееся объектом аренды,
          в течение оговоренного сторонами срока передачи помещения, который являетсся
          частью существенных условий по настоящему договору.
          <div className={styles.NewLine}/>
          7.2 Передать (предоставить) Арендатору жилое помещение, предусмотренное настоящим
          договором, в состоянии, соответствующем условиям договора аренды, назначению арендованного
          помещения и его пригодности для проживания людей.
          <div className={styles.NewLine}/>
          7.4 В присутствии Арендатора по договору аренды проверить исправность систем жизнеобеспечения
          сдаваемого в аренду жилого помещения, а также ознакомить Арендатора с правилами эксплуатации
          его либо выдать Арендатору письменные инструкции о правилах и порядке пользования
          этим помещением и его систем жизнеобеспечения.
          <div className={styles.NewLine}/>
          7.5 Оказывать в период действия договора аренды Арендатору информационную и иную помощь в
          целях наиболее эффективного использования Арендатором жилого помещения, переданного
          ему во временное владение и пользование по настоящему договору аренды.
          <div className={styles.NewLine}/>
          7.6 В течение всего срока действия договора аренды жилого помещения, осуществлять его
          капитальный ремонт (по мере необходимости) с предоставлением необходимых
          расходных материалов и иных принадлежностей.
        </p>

        <h2>
          8. Обязанности арендатора.
        </h2>

        <p>
          8.1 Использовать полученное в аренду жилое помещение в соответствии с
          условиями настоящего договора и исключительно по прямому назначению
          названного помещения, т.е. для проживания.
          <div className={styles.NewLine}/>
          8.2. Поддерживать жилое помещение в надлежащем состоянии.
          <div className={styles.NewLine}/>
          8.3 Возместить Арендодателю убытки, причиненные в случае повреждения
          арендованного жилого помещения, если Арендодатель докажет, что повреждение
          жилого помещения произошло в результате наступления обстоятельств, за которые
          Арендатор несет ответственность в соответствии с действующим законодательством
          или условиями настоящего договора аренды.
          <div className={styles.NewLine}/>
          8.4 Возвратить арендованное жилое помещение в день истечения срока
          аренды или в день досрочного прекращения настоящего договора.
        </p>

        <h2>
          9. Риск случайной гибели или повреждения жилого помещения.
        </h2>

        <p>
          9.1 Если иное не будет установлено сторонами настоящего договора аренды в процессе исполнения
          его условий, риск случайной гибели или случайного повреждения переданного в аренду жилого
          помещения в течение срока действия договора несет Арендодатель.
          <div className={styles.NewLine}/>
          9.2 Страхование принятого в аренду жилого помещения в течение всего срока действия
          договора аренды (от момента принятия его от Арендодателя и до момента сдачи помещения последнему)
          осуществляет Арендодатель. Выбор видов страхования принадлежит арендодателю.
        </p>

        <h2>
          10. Ответственность сторон и разрешение споров.
        </h2>

        <p>
          10.1. Стороны несут ответственность за неисполнение или ненадлежащее исполнение настоящего
          договора в порядке, предусмотренном настоящим договором и действующим законодательством РФ.
          <div className={styles.NewLine}/>
          10.2. Все споры и разногласия, возникающие при исполнении сторонами обязательств по настоящему
          Договору, решаются путем переговоров. В случае невозможности их устранения, СТОРОНЫ имеют
          право обратиться за судебной защитой своих интересов.
        </p>

        <h2>
          11. Расторжение договора аренды и возврат денежных средств.
        </h2>

        <p>
          11.1. По требованию Арендодателя настоящий договор аренды может быть досрочно расторгнут в случаях, когда Арендатор:
          пользуется помещением с существенным нарушением условий договора или назначения помещения либо с неоднократными нарушениями;
          существенно ухудшает жилое помещение;
          <div className={styles.NewLine}/>
          11.2. По требованию Арендатор договор аренды может быть досрочно расторгнут в случаях, когда:

          <ol className={cn(styles.List, styles.ListNumber)}>
            <li>
              Арендодатель не предоставляет жилое помещение в пользование Арендатору либо создает препятствия пользованию
              этим жилым помещением в соответствии с условиями договора или назначением его;
            </li>
            <li>
              переданное Арендатору жилое помещение имеет препятствующие пользованию им недостатки,
              которые не были оговорены Арендодателем при заключении договора, не были заранее известны Арендатору
              и не должны были быть обнаружены Арендатором во время осмотра этого помещения его при заключении договора;
            </li>
            <li>
              арендованное помещение в силу обстоятельств, за которые Арендатор не отвечает, окажется в состоянии, не пригодном для использования.
            </li>
          </ol>
          <br/>
          11.3 В случае досрочного расторжения договора, неиспользованная часть средств, авансированных
          Арендатором, подлежит возврату Арендатору в течение 7 дней со дня документального оформления такого
          расторжения, по указанным Арендатором реквизитам.
        </p>

        <h2>
          12. Форс-мажорные обстоятельства.
        </h2>

        <p>
          12.1. Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение обязательств по договору
          на время действия непреодолимой силы. Под непреодолимой силой понимаются чрезвычайные и непреодолимые
          при данных условиях обстоятельства, препятствующие исполнению своих обязательств сторонами по настоящему
          Договору. К ним относятся стихийные явления (землетрясения, наводнения и т. п.), обстоятельства
          общественной жизни (военные действия, чрезвычайные положения, крупнейшие забастовки, эпидемии и т. п.),
          запретительные меры государственных органов (запрещение перевозок, валютные ограничения, международные
          санкции запрета на торговлю и т. п.). В течение этого времени стороны не имеют взаимных претензий, и каждая
          из сторон принимает на себя свой риск последствия форс-мажорных обстоятельств.
        </p>

        <h2>
          13. Срок действия договора.
        </h2>

        <p>
          13.1. Настоящий договор вступает в силу с момента обращения принятия настоящий оферты
          и существенных условий к ней, и заканчивается при полном исполнении обязательств сторонами.
        </p>
      </section>
    </main>
  )
}

export default Oferta
