import React from 'react'
import SectionBecameAnAgent from '../../components/SectionBecameAnAgent'
import { useScrollToTop } from '../../components/ScrollToTop'
import styles from './index.module.scss'

const AgentFormPage = () => {
  useScrollToTop()
  return (
    <main className={styles.Main}>
      <SectionBecameAnAgent />
    </main>
  )
}

export default AgentFormPage
