import React, { useEffect, useState } from 'react'
import styles from '../index.module.scss'
import classNames from 'classnames'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import { ReactComponent as ArrowIcon } from '../../../assets/arrow-light-grey.svg'
import { ObjectProps } from '../../../api'

const cn = classNames.bind(styles)
const Carousel = ({ record }: { record: ObjectProps}) => {
  const [pageWidth, setPageWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth)
    }

    // Add event listener to update page width on window resize
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const isLaptop = pageWidth > 970
  const isPhone = pageWidth < 670
  let widthImg = isLaptop ? 748 : 567
  let heightImg = isLaptop ? 519 : 394
  if (isPhone) {
    widthImg = 300
    heightImg = 211
  }

  const images = record?.images?.map((item) => {
    return (
      <img
        width={widthImg}
        height={heightImg}
        key={item.id}
        src={item.image}
        alt={item.name}
        style={{ display: "block", marginLeft: '8px', marginRight: '8px'}}
      />
    )
  })

  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: {
      items: 3,
    },
  }

  const renderPrevButton = () => {
    return (
      <div className={cn(styles.ControlBtn, styles.ControlBtnLeft)}>
        <ArrowIcon />
      </div>
    )
  }

  const renderNextButton = () => {
    return (
      <div className={cn(styles.ControlBtn, styles.ControlBtnRight)}>
        <ArrowIcon />
      </div>
    )
  }

  const padding = (pageWidth - (isLaptop ? 748 : 567)) / 2

  return (
    <div className={cn('container', styles.CarouselContainer)}>
      <AliceCarousel
        controlsStrategy='responsive'
        mouseTracking
        items={images}
        paddingLeft={padding}
        paddingRight={padding}
        infinite={true}
        activeIndex={1}
        autoWidth={true}
        responsive={responsive}
        disableDotsControls
        renderPrevButton={renderPrevButton}
        renderNextButton={renderNextButton}
      />
    </div>
  )
}

export default Carousel
