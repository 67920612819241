import { URL } from './constants'
import { QuestionItemProps } from '../pages/FAQ/components/QuestionItem.d'
import { TeamMemberProps } from '../pages/Team/components/TeamMember.d'
import { Dispatch, SetStateAction } from 'react'
import axios from 'axios'
interface ImageProps {
  id: string
  name: string
  image: string
}

export interface FacilityProps {
  id: string
  name: string
  category: string
}

export interface ObjectProps {
  id: string
  name: string
  image: string
  images: ImageProps[]
  facilities?: FacilityProps[]
  type?: 'real_estate' | 'yacht' | 'helicopter'
  description?: string
  country: ObjectProps
  lat: number
  lon: number
  price: string
}
export interface ObjectListProps {
  count: number
  next: string
  previous: string
  results: ObjectProps[]
}

export interface PostOfferProps {
  name?: string
  surname?: string
  middle_name?: string
  phone?: string
  email?: string
  comment?: string
  object?: string
  price?: string
  start_at?: string
  end_at?: string
  countryId?: string
  objectType?: 'real_estate' | 'helicopter' | 'yacht'
}

interface BecameAgentProps {
  name: string
  email: string
  phone: string
  message: string
  attachment?: Blob
  fileName?: string
}

export interface BookingProps {
  id: string
  created_at: Date
  customer_name: string
  customer_surname: string
  customer_middle_name: string
  email: string
  phone: string
  start_at: Date
  end_at: Date
  price_currency: string
  price: string
  comment: string
  status: string
  public_id: string
  is_client_accept_offer: boolean,
  client_accept_offer_at: Date
  object: string
}

export interface ObjectReservationProps {
  id: string
  object: ObjectProps
  "created_at": string
  "updated_at": string
  "customer_name_and_surname": string
  "start_at": string
  "end_at": string
  "price_currency": string
  "price": string
  "status": string
  "public_id": string
  "is_client_accept_offer": boolean
  "client_accept_offer_at": string
}

export const fetchQuestions = async (setQuestions: Dispatch<SetStateAction<QuestionItemProps[]>>) => {
  try {
    const response = await fetch(URL('faq'))
    const jsonData = await response.json()
    jsonData?.results && setQuestions(jsonData?.results as QuestionItemProps[] || [])
    return jsonData
  } catch (error) {
    console.log('Error:', error)
  }
}

export const fetchStaff = async (setStaff: Dispatch<SetStateAction<TeamMemberProps[]>>) => {
  try {
    const response = await fetch(URL('staff'))
    const jsonData = await response.json()
    setStaff(jsonData as TeamMemberProps[] || [])
    return jsonData
  } catch (error) {
    console.log('Error:', error)
  }
}

export const fetchCountries = async (setCountryList: Dispatch<SetStateAction<ObjectProps[]>>, limit?: number) => {
  try {
    const response = await fetch(URL(`countries${limit ? `/?limit=${limit}` : ''}`))
    const jsonData = await response.json()
    jsonData?.results && setCountryList(jsonData?.results as ObjectProps[] || [])
    return jsonData
  } catch (error) {
    console.log('Error:', error)
  }
}

export const fetchObjectList = async ({
  setObjectList,
  type,
  countryId,
  limit,
}: {
  setObjectList: Dispatch<SetStateAction<ObjectProps[]>>
  type: 'real_estate' | 'yacht' | 'helicopter'
  countryId?: string
  limit?: number
}) => {
  try {
    const response = countryId
      ? await fetch(URL(`objects/?type=${type}&country_id=${countryId}${limit ? `/?limit=${limit}` : ''}`))
      : await fetch(URL(`objects/?type=${type}`))
    const jsonData = await response.json()
    jsonData?.results && setObjectList(jsonData?.results as ObjectProps[] || [])
    return jsonData
  } catch (error) {
    console.log('Error:', error)
  }
}

export const fetchObjectById = async (id: string, setObject: Dispatch<SetStateAction<ObjectProps | undefined>>) => {
  try {
    const response = await fetch(URL(`objects/${id}`))
    const jsonData = await response.json()
    setObject(jsonData as ObjectProps)
    return jsonData
  } catch (error) {
    console.log('Error:', error)
  }
}

export const fetchObjectsByCountryId = async (id: string, setObject: Dispatch<SetStateAction<ObjectProps[]>>) => {
  try {
    const response = await fetch(URL(`objects/?country_id=${id}`))
    const jsonData = await response.json()
    jsonData?.results && setObject(jsonData?.results as ObjectProps[])
    return jsonData
  } catch (error) {
    console.log('Error:', error)
  }
}

export const postReqGetOffer = async (data: PostOfferProps) => {
  try {
    const formData = new FormData()
    data.name && formData.append('customer_name', data.name)
    data.surname && formData.append('customer_surname', data.surname || '')
    data.middle_name && formData.append('customer_middle_name', data.middle_name || '')
    formData.append('phone', data.phone || '')
    data.email && formData.append('email', data.email || '')
    formData.append('comment', data.comment || '')
    formData.append('object', data.object || '')
    formData.append('start_at', data.start_at || new Date().toDateString())
    formData.append('end_at', data.end_at || new Date().toDateString())
    formData.append('price', data.price || '')

    await axios.post(
      URL('bookings/'), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    return true
  } catch (e) {
    console.log(e)
  }
}

export const fetchReservation = async (id: string, setObject: Dispatch<SetStateAction<ObjectReservationProps | null>>) => {
  try {
    const response = await fetch(URL(`bookings-v2/${id}`))
    const jsonData = await response.json()
    setObject(jsonData as ObjectReservationProps)
    return jsonData
  } catch (error) {
    console.log('Error:', error)
  }
}

export const postBecameAnAgent = async (data: BecameAgentProps) => {
  try {
    const formData = new FormData()
    formData.append('name', data.name)
    formData.append('email', data.email)
    formData.append('phone', data.phone)
    formData.append('message', data.message)
    formData.append('type', 'client')
    data.attachment && formData.append('attachment', data.attachment, data.fileName)

    await axios.post(
      URL('callback-request/'), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    return true
  } catch (e) {
    console.log(e)
  }
}

export const fetchBookingById = async (id: string, setBooking: Dispatch<SetStateAction<BookingProps | undefined>>) => {
  try {
    const response = await fetch(URL(`bookings/${id}`))
    const jsonData = await response.json()
    setBooking(jsonData)
    return jsonData
  } catch (error) {
    console.log('Error:', error)
  }
}

export const postBookingApproveAndPay = async (publicId: string) => {
  try {
    const formData = new FormData()
    formData.append('is_client_accept_offer', 'true')

    return await axios.post(
      URL(`bookings/${publicId}/pay/`), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
  } catch (e) {
    console.log(e)
  }
}

