import React, { lazy, Suspense } from 'react'

const PointsSectionComponent = lazy(
  () => import('./components/PointsSection'),
)
import HomePageSection from './components/HomePageSection'
import styles from './index.module.scss'
import MainSection from './components/MainSection'
import { useScrollToTop } from '../../components/ScrollToTop'

const HomePage = () => {
  useScrollToTop()

  return (
    <main style={{ overflow: 'hidden' }}>
      <MainSection />

      <HomePageSection
        title="Популярные направления"
        btnUrl="/countries"
        withImgCaption={true}
        type="countries"
      />

      <HomePageSection
        title="Рекомендуем к посещению"
        btnUrl="/recommended-to-visit"
        type="recommended-to-visit"
      />

      <HomePageSection
        title={`Лучшие яхты со \nвсего мира`}
        btnUrl="/yachts"
        titleClassName={styles.SectionTitle}
        type="yachts"
      />

      <HomePageSection
        title="Вертолеты"
        btnUrl="/helicopters"
        type="helicopters"
      />

      <Suspense fallback={<span>Loading</span>}>
        <PointsSectionComponent />
      </Suspense>
    </main>
  )
}

export default HomePage
