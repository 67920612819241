import React, {useEffect, useState} from 'react'
import InnerPageContainer from '../../components/InnerPageContainer'
import ImgItem from '../../components/ImgItem/ImgItem'
import styles from './index.module.scss'
import { ObjectProps, fetchObjectList } from '../../api'
import { useScrollToTop } from '../../components/ScrollToTop'
import { Link } from 'react-router-dom'

const HelicoptersPage = () => {
  const [helicopterList, setHelicopterList] = useState<ObjectProps[]>([])

  useEffect(() => {
    fetchObjectList({ setObjectList: setHelicopterList, type: 'helicopter' })
  }, [])

  useScrollToTop()

  return (
    <>
      <InnerPageContainer
        title='Все вертолеты'
        bigLayout={true}
        className={styles.InnerPageContainer}
      >
        {
          helicopterList?.map((item) => {
            return (
              <Link key={item.id} to={`/offers/${item.id}`} >
                <ImgItem
                  withImgCaption={false}
                  key={item.id}
                  imgUrl={item.images?.[0]?.image}
                  name={item.country.name}
                  description={item.name}
                  price={(+item.price / 24).toString()}
                  classNameCaption={styles.Caption}
                  type='helicopter'
                />
              </Link>
            )
          })
        }
      </InnerPageContainer>
    </>
  )
}

export default HelicoptersPage
