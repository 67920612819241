import React from 'react'
import { FacilityProps } from '../../../api'

const FacilityList = ({
  list,
  title,
}: { list: FacilityProps[], title: string }) => {
  return (
    <>
      <h3>{title}</h3>

      <ul>
        {
          list?.map((item) => {
            return (
              <li key={item.id}>
                {item.name}
              </li>
            )
          })
        }
      </ul>
    </>
  )
}

export default FacilityList