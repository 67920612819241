import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'
import HomePage from './pages/Home/index'
import FAQPage from './pages/FAQ/index'
import TeamPage from './pages/Team/index'
import Header from './components/Header'
import Footer from './components/Footer'
import CountriesPage from './pages/Countries'
import RealEstatePage from './pages/RealEstate'
import HelicoptersPage from './pages/Helicopters'
import YachtsPage from './pages/Yachts'
import ObjectPage from './pages/ObjectPage'
import Page404 from './pages/Page404'
import AgentFormPage from './pages/AgentFormPage'
import PolicyPage from './pages/Policy'
import CountryPage from './pages/Country'
import SuccessPage from './pages/Success'
import SecurityPolicyPage from './pages/SecurityPolicy'
import BookingApprovePage from './pages/BookingApprove'
import OfertaPage from './pages/Oferta'
import BookingSuccessPage from './pages/BookingSuccess'
import BookingRejectedPage from './pages/BookingRejected'
import OfferPage from './pages/OfferPage'
import OfferRentalPage from './pages/OfferRentalPage'
import RentalPage from './pages/Rental'
import PolicyBest2Pay from './pages/PolicyBest2Pay'
import ReservationPage from './pages/ReservationPage'

function App() {
  return (
    <div className="App">
      <Router>
        <Header />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/agent-form" element={<AgentFormPage />} />

          <Route path="/countries" element={<CountriesPage />} />
          <Route
            path="/recommended-to-visit"
            element={<RealEstatePage />}
          />
          <Route path="/helicopters" element={<HelicoptersPage />} />
          <Route path="/yachts" element={<YachtsPage />} />
          <Route path="/policy" element={<PolicyPage />} />
          <Route path="/policy-best-2-pay" element={<PolicyBest2Pay />} />
          <Route path="/offer" element={<OfferPage />} />
          <Route path="/offer-rental" element={<OfferRentalPage />} />
          <Route path="/rental" element={<RentalPage />}/>
          <Route path="/reservation/:id" element={<ReservationPage />}/>

          <Route path="/offers/:id" element={<ObjectPage />} />
          <Route path="/country/:id" element={<CountryPage />} />

          <Route path="/success" element={<SuccessPage />} />
          <Route path="/b2p_policy" element={<SecurityPolicyPage />} />
          <Route path="/oferta" element={<OfertaPage />} />
          <Route path="/booking/:id" element={<BookingApprovePage />} />
          <Route path="/booking/success" element={<BookingSuccessPage />} />
          <Route path="/booking/rejected" element={<BookingRejectedPage />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
        <Footer />
      </Router>

      <div id="modal"></div>
    </div>
  )
}

export default App
