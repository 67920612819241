import React from 'react'
import classNames from 'classnames'
import { useScrollToTop } from '../../components/ScrollToTop'
import logoBest2Pay from '../../assets/logo-best2pay-black.svg'
import mir from "../../assets/mir.svg"
import mastercard from "../../assets/mastercard.svg"
import visa from "../../assets/visa.svg"
import styles from './index.module.scss'


const cn = classNames.bind(styles)

const SecurityPolicyPage = () => {
  useScrollToTop()

  return (
    <>
      <main className={cn('container', styles.Main)}>
        <section>
          <div className={styles.Partners}>
            <img src={logoBest2Pay} alt="Logo Best 2 Pay"/>
            <img src={mir} alt="Logo Mir"/>
            <img src={mastercard} alt="Logo Mastercard"/>
            <img src={visa} alt="Logo Visa"/>
          </div>

          <h1>
            Политика безопасности Бест2Пэй
          </h1>

          <p>
            Оплатить заказ можно с помощью банковских карт платёжных систем Visa, MasterCard, МИР.
          </p>

          <p>
            При оплате банковской картой безопасность платежей гарантирует процессинговый центр Best2Pay.
          </p>

          <p>
            Приём платежей происходит через защищённое безопасное соединение, используя протокол TLS 1.2.
          </p>

          <p>
            Компания Best2Pay соответствует международным требованиями PCI DSS для обеспечения безопасной
            обработки реквизитов банковской карты плательщика.
          </p>

          <p>
            Ваши конфиденциальные данные необходимые для оплаты (реквизиты карты, регистрационные данные и др.) не поступают в Интернет-магазин,
            их обработка производится на стороне процессингового центра Best2Pay и полностью защищена.
          </p>

          <p>
            Никто, в том числе интернет-магазин listrentals.ru, не может получить банковские и персональные данные плательщика.
          </p>

          <p>
            При оплате заказа банковской картой возврат денежных средств производится на ту же самую карту, с которой был произведён платёж
          </p>
        </section>
      </main>
    </>
  )
}

export default SecurityPolicyPage
