import React, {useState} from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'
import arrow from '../../../assets/arrow.svg'
import { QuestionItemProps } from './QuestionItem.d'

const cn = classNames.bind(styles)
const QuestionItem = (question: QuestionItemProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      className={styles.QuestionItemContainer}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className={cn(styles.QuestionItemTitle, isOpen ? styles.QuestionItemTitleOpen : undefined)}>
        {question.question}
        <img src={arrow} alt="Arrow"/>
      </div>
      <div className={cn(styles.QuestionItemContent, isOpen ? styles.QuestionItemContentOpen : undefined )}>
        {question.answer}
      </div>
    </div>
  )
}

export default QuestionItem