import React, { useEffect } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import success from '../../assets/success.svg'

const cn = classNames.bind(styles)
const SuccessPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const appElement = document.querySelector('.App')
    if (appElement) {
      appElement.classList.add('displayFlexClass')
    }

    return () => {
      if (appElement) {
        appElement.classList.remove('displayFlexClass')
      }
    }
  }, [])

  return (
    <main className={cn('container', styles.SuccessWrapper)}>
      <img
        src={success}
        alt="Success"
        className={styles.SuccessImg}
      />
      <h1>Готово</h1>
      <p>
        Мы получили вашу заявку. После <br />
        рассмотрения вашей анкеты, <br />
        менеджер свяжется с вами
      </p>
      <Button onClick={() => navigate('/')}>
        Вернуться на главную
      </Button>
    </main>
  )
}

export default SuccessPage
