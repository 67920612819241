import React, { useEffect, useState } from 'react'
import InnerPageContainer from '../../components/InnerPageContainer'
import ImgItem from '../../components/ImgItem/ImgItem'
import styles from './index.module.scss'
import { ObjectProps, fetchObjectList } from '../../api'
import { useScrollToTop } from '../../components/ScrollToTop'
import { Link } from 'react-router-dom'

const YachtsPage = () => {
  const [yachtList, setYachtList] = useState<ObjectProps[]>([])

  useEffect(() => {
    fetchObjectList({ setObjectList: setYachtList, type: 'yacht' })
  }, [])

  useScrollToTop()

  return (
    <>
      <InnerPageContainer title="Лучшие яхты со всего мира">
        {yachtList?.map((item) => {
          return (
            <Link key={item.id} to={`/offers/${item.id}`}>
              <ImgItem
                withImgCaption={false}
                key={item.id}
                imgUrl={item.images?.[0]?.image}
                name={item.country.name}
                description={item.name}
                classNameImage={styles.Image}
                classNameWrapper={styles.Container}
                classNameCaption={styles.Caption}
              />
            </Link>
          )
        })}
      </InnerPageContainer>
    </>
  )
}

export default YachtsPage
